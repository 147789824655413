import styled from 'styled-components';

export const FinalNote = styled.p`
  padding-top: 2em;
  padding-bottom: 2em;
  color:var(--grayDarker);
  font-size: 11px;;
  text-align:center;
  
  a {
    font-size: inherit;
  }
`;
